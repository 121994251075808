import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';

import Button from 'shopper/components/Button';
import Heading from 'shopper/components/Heading';
import Icon from 'shopper/components/Icon';
import Link from 'shopper/components/Link';
import Text from 'shopper/components/Text';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import useModal from 'hooks/useModal';

import { sendEvent } from 'lib/gtag';
import {
  toCategoryLink,
  toLandingPageLink,
  toSubcategoryLink,
} from 'lib/links';
import placeholder from 'lib/placeholder';
import { landingQueryKeys } from 'lib/queryKeys';

import { getLandingsList } from 'services/landing';

import WhatIsPromobitDescription from './WhatIsPromobitDescription';

import APP from 'constants/app';
import LANDINGS from 'constants/landings';
import LINKS from 'constants/links';
import MOBILE_APPS from 'constants/mobileApp';
import SOCIAL_MEDIA from 'constants/socialMedia';

const WishlistModal = dynamic(
  () => import('components/Wishlist/WishlistModal'),
  {
    ssr: false,
  }
);

const APPS_GA_EVENT_CATEGORY = 'footer_app';

const SOCIAL_MEDIA_LIST = [
  {
    name: SOCIAL_MEDIA.FACEBOOK.TITLE,
    href: SOCIAL_MEDIA.FACEBOOK.URL,
    icon: <Icon name="facebook" />,
  },
  {
    name: SOCIAL_MEDIA.TWITTER.TITLE,
    href: SOCIAL_MEDIA.TWITTER.URL,
    icon: <Icon name="twitter" />,
  },
  {
    name: SOCIAL_MEDIA.INSTAGRAM.TITLE,
    href: SOCIAL_MEDIA.INSTAGRAM.URL,
    icon: <Icon name="instagram" />,
  },
  {
    name: SOCIAL_MEDIA.LINKEDIN.TITLE,
    href: SOCIAL_MEDIA.LINKEDIN.URL,
    icon: <Icon name="linkedin" />,
  },
  {
    name: SOCIAL_MEDIA.TELEGRAM.TITLE,
    href: SOCIAL_MEDIA.TELEGRAM.URL,
    icon: <Icon name="telegram" />,
  },
];

const INSTITUTIONAL_LINKS_LIST = [
  { name: 'Comercial', href: LINKS.BUSINESS },
  {
    name: 'Fale conosco',
    href: toLandingPageLink('contato', LANDINGS.TYPES.INSTITUTIONAL),
  },
  { name: 'Política de Cookies', href: LINKS.COOKIES_POLICY },
  {
    name: 'Política de Privacidade',
    href: toLandingPageLink(
      'politica-de-privacidade',
      LANDINGS.TYPES.INSTITUTIONAL
    ),
  },
  {
    name: 'Termos de Uso',
    href: toLandingPageLink('termos', LANDINGS.TYPES.INSTITUTIONAL),
  },
  { name: 'Mapa Site', href: LINKS.SITEMAP },
];

const CASH3_LINKS_LIST = [
  { name: 'IDinheiro', href: LINKS.IDINHEIRO },
  { name: 'Melhor Plano', href: LINKS.MELHOR_PLANO },
  { name: 'Méliuz', href: LINKS.MELIUZ },
  { name: 'Minha Conexão', href: LINKS.MINHA_CONEXAO },
  { name: 'Muambator', href: LINKS.MUAMBATOR },
];

const SPECIAL_PAGES_LINKS_LIST = [
  {
    name: 'Black Friday',
    href: toLandingPageLink('black-friday'),
  },
  {
    name: 'Celulares em promoção',
    href: toCategoryLink('smartphones-tablets-e-telefones'),
  },
  {
    name: 'Fones de ouvido em promoção',

    href: toSubcategoryLink('fone-de-ouvido'),
  },
  {
    name: 'Games em promoção',
    href: toCategoryLink('games'),
  },
  {
    name: 'Geladeiras em promoção',
    href: toSubcategoryLink('geladeiras'),
  },
  {
    name: 'Fralda Pampers em promoção',
    href: toSubcategoryLink('fralda-pampers'),
  },
  { name: 'TVs em promoção', href: toSubcategoryLink('tv') },
];

const HELP_LINKS_LIST = [
  {
    name: 'Diretrizes da comunidade',
    href: LINKS.COMMUNITY_GUIDELINES,
  },
  {
    name: 'Critérios de moderação',
    href: toLandingPageLink(
      'criterios-de-moderacao',
      LANDINGS.TYPES.INSTITUTIONAL
    ),
  },
  { name: 'Perguntas frequentes', href: LINKS.FAQ },
  { name: 'Problemas com sua compra?', href: LINKS.PURCHASE_ISSUES },
];

const onPlayStoreClick = () =>
  sendEvent({ category: APPS_GA_EVENT_CATEGORY, action: 'google_play' });

const onAppStoreClick = () =>
  sendEvent({ category: APPS_GA_EVENT_CATEGORY, action: 'apple_store' });

const FooterMain = () => {
  const { showModal } = useModal();

  const { data: featuredLanding } = useQuery({
    queryKey: landingQueryKeys.featured(),
    queryFn: ({ signal }) => getLandingsList({ featured: true }, { signal }),
    enabled: false,
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="mx-auto mt-6 grid grid-flow-row grid-cols-4 grid-rows-[auto] gap-x-4 px-2 sm:px-4 md:mt-10 md:max-w-5xl md:grid-flow-col md:grid-cols-12 md:px-8 lg:max-w-7xl lg:gap-x-6 lg:px-6">
      <div className="col-span-1 col-start-1 h-fit sm:col-span-4 lg:col-span-3">
        <div className="hidden md:block">
          <Heading
            as="p"
            className="mb-3 text-center text-lg md:text-left lg:text-base"
            size="size5"
          >
            {placeholder('titles.whatIsPromobit')}
          </Heading>
          <WhatIsPromobitDescription />
        </div>
        <div className="mt-8 hidden flex-col md:flex">
          <Heading
            as="p"
            className="mb-3 text-center text-lg md:text-left lg:text-base"
            size="size5"
          >
            {placeholder('titles.wishlist')}
          </Heading>
          <Text className="mb-4 text-base lg:text-sm lg:leading-6" size="size1">
            {placeholder('descriptions.whatIsWishlist')}
          </Text>
          <Button
            iconLeft={<Icon name="heart" />}
            size="size2"
            type="secondary-stroke"
            full
            onClick={() => {
              showModal(WishlistModal);
            }}
          >
            {placeholder('actions.openWishlist')}
          </Button>
        </div>
      </div>
      <div className="col-span-4 grid h-fit md:mt-8 lg:col-span-3 lg:col-start-4 lg:mt-0 xl:col-start-5">
        <div className="mb-8">
          <Heading
            as="p"
            className="mb-4 text-center text-lg md:text-left lg:mb-3 lg:text-base"
            size="size5"
          >
            {placeholder('titles.institutional')}
          </Heading>
          <div className="flex flex-col flex-wrap items-center justify-start md:items-start">
            {INSTITUTIONAL_LINKS_LIST.map(({ name, href }) => (
              <Link
                key={name}
                className="py-2 text-base leading-8 xl:mb-1 xl:py-0 xl:text-sm xl:leading-5"
                href={href}
                size="size1"
                type="secondary"
              >
                {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="mb-8 flex flex-col items-center md:items-start">
          <Heading
            as="p"
            className="mb-4 text-center text-lg md:text-left lg:text-base"
            size="size5"
          >
            {placeholder('titles.cash3Group')}
          </Heading>
          <div className="flex flex-col flex-wrap items-center justify-start md:items-start">
            {CASH3_LINKS_LIST.map(({ name, href }) => (
              <Link
                key={name}
                className="py-2 text-base leading-8 xl:mb-1 xl:py-0 xl:text-sm xl:leading-5"
                href={href}
                size="size1"
                type="secondary"
              >
                {name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-4 h-fit md:col-start-7 lg:col-span-3 xl:col-start-9">
        <div className="mb-8">
          <Heading
            as="p"
            className="mb-4 text-center text-lg md:text-left lg:mb-3 lg:text-base"
            size="size5"
          >
            {placeholder('titles.specialPages')}
          </Heading>
          <div className="flex flex-col flex-wrap items-center justify-start md:items-start">
            {featuredLanding && (
              <Link
                key={featuredLanding.landingId}
                className="py-2 text-base leading-8 xl:mb-1 xl:py-0 xl:text-sm xl:leading-5"
                href={toLandingPageLink(
                  featuredLanding.landingSlug,
                  featuredLanding.landingType
                )}
                size="size1"
                type="secondary"
              >
                {featuredLanding.landingName}
              </Link>
            )}
            {SPECIAL_PAGES_LINKS_LIST.map(({ name, href }) => {
              if (
                featuredLanding &&
                href ===
                  toLandingPageLink(
                    featuredLanding.landingSlug,
                    featuredLanding.landingType
                  )
              ) {
                return null;
              }

              return (
                <Link
                  key={name}
                  className="py-2 text-base leading-8 xl:mb-1 xl:py-0 xl:text-sm xl:leading-5"
                  href={href}
                  size="size1"
                  type="secondary"
                >
                  {name}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="mb-8 flex flex-col items-center md:items-start">
          <Heading
            as="p"
            className="mb-4 text-center text-lg md:text-left lg:mb-3 lg:text-base"
            size="size5"
          >
            {placeholder('titles.secureSite')}
          </Heading>
          <div className="flex flex-row items-start">
            <Image
              alt="Certificado Google Safe Browsing"
              height={33}
              src={`${APP.LOCAL_IMAGE_PATH}/features/footer/badge-google-safe-browsing.png`}
              width={114}
            />
            <Image
              alt="Certificado Google for Startups Accelerator 2020"
              className="ml-4"
              height={40}
              src={`${APP.LOCAL_IMAGE_PATH}/features/footer/badge-google-for-startups-accelerator-2020.png`}
              width={110}
            />
          </div>
        </div>
      </div>
      <div className="col-span-4 col-start-1 grid h-fit md:col-start-7 md:mt-8 lg:col-span-3 lg:col-start-10 lg:mt-0 xl:col-start-13">
        <div className="mb-8">
          <Heading
            as="p"
            className="mb-4 text-center text-lg md:text-left lg:mb-3 lg:text-base"
            size="size5"
          >
            {placeholder('titles.helpArticles')}
          </Heading>
          <div className="flex flex-col flex-wrap items-center justify-start md:items-start">
            {HELP_LINKS_LIST.map(({ name, href }) => (
              <Link
                key={name}
                className="py-2 text-base leading-8 xl:mb-1 xl:py-0 xl:text-sm xl:leading-5"
                href={href}
                size="size1"
                type="secondary"
              >
                {name}
              </Link>
            ))}
          </div>
        </div>
        <div className="mb-6">
          <Heading
            as="p"
            className="mb-4 text-center text-lg md:text-left lg:mb-3 lg:text-base"
            size="size5"
          >
            {placeholder('titles.downloadAppShortest')}
          </Heading>
          <div className="flex items-center justify-center md:flex-col md:items-start md:justify-start">
            <Anchor
              href={LINKS.APP_DOWNLOAD}
              rel="noreferrer"
              target="_blank"
              onClick={onPlayStoreClick}
            >
              <Image
                alt={MOBILE_APPS.ANDROID.DESCRIPTION}
                height={36}
                src={`${APP.LOCAL_IMAGE_PATH}/features/footer/badge-google-play.svg`}
                width={116}
              />
            </Anchor>
            <Anchor
              className="ml-4 md:ml-0 md:mt-2"
              href={LINKS.APP_DOWNLOAD}
              rel="noreferrer"
              target="_blank"
              onClick={onAppStoreClick}
            >
              <Image
                alt={MOBILE_APPS.IOS.DESCRIPTION}
                height={37}
                src={`${APP.LOCAL_IMAGE_PATH}/features/footer/badge-apple-store.svg`}
                width={123}
              />
            </Anchor>
          </div>
        </div>
        <div>
          <Heading
            as="p"
            className="mb-4 text-center text-lg md:text-left lg:mb-3 lg:text-base"
            size="size5"
          >
            {placeholder('titles.followUs')}
          </Heading>
          <div className="flex justify-center md:justify-start">
            {SOCIAL_MEDIA_LIST.map(({ name, href, icon }) => (
              <Button
                key={name}
                aria-label={`${name} do Promobit`}
                as="a"
                href={href}
                icon={icon}
                rel="noreferrer"
                size="size1"
                target="_blank"
                type="neutral-ghost"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMain;
